
 export const staking_addr = "0x04F6c20B1BC054bD9341C3a7Efc6944b53A1A14D"
 export const hestoken_addr = "0xd4F4313D72F5BA010999de120C755279918e6821"

 //Binance Testnet
export const busdAddr = "0x1B1043E1048a4197E315B4b04A030A2bB74c1295"

 //Binance Testnet Router 
 export const router_addr = "0xD99D1c33F9fC3444f8101754aBC46c52416550D1"

//Binance Mainnet
//export const busdAddr = "0xe9e7cea3dedca5984780bafc599bd69add087d56"

//Binance Mainnet Router 
 //export const router_addr = "0x10ED43C718714eb63d5aA57B78B54704E256024E"
